import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import "./style.scss";
import Button from "../../ButtonComponent";
// import { Link } from "gatsby";

export default function PartnerSection() {
  let ref1 = React.createRef(null);

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    // gsap.to(".layout", {
    //   scrollTrigger: {
    //     trigger: ref1.current,
    //     start: "10% center",
    //     end: "bottom center",
    //     toggleActions: "play reverse play reverse",
    //     // markers: true,
    //     toggleClass: { targets: ".layout", className: "layout--bgblack" },
    //     invalidateOnRefresh: true,
    //   },
    //   ease: "linear",
    //   stagger: 0.1,
    //   // backgroundColor: "black",
    //   duration: 0.7,
    // });

    // gsap.from(".moveupanim1", {
    //   scrollTrigger: {
    //     trigger: ref1.current,
    //     start: "0% center",
    //     end: "bottom center",
    //     toggleActions: "play none play reverse",
    //     // markers: true,
    //   },

    //   stagger: 0.3,

    //   ease: "Circ.easeOut",
    //   duration: 0.7,
    //   rotate: 10,
    //   opacity: 0,
    //   y: 300,
    // });
    const cursor = document.querySelector(".layout__cursor");
    const container = document.querySelector(".aboutsection");

    container.addEventListener("mouseenter", onMouseEnterContainer1);
    container.addEventListener("mouseleave", onMouseLeaveContainer1);

    function onMouseEnterContainer1(e) {
      cursor.classList.add("layout__cursor--dark");
    }
    function onMouseLeaveContainer1(e) {
      cursor.classList.remove("layout__cursor--dark");
    }

    // gsap.from(".moveupanim", {
    //   scrollTrigger: {
    //     trigger: serviceref.current,
    //     start: "-=30% center",
    //     end: "90% center",
    //     toggleActions: "play none play reverse",
    //   },
    //   rotate: 10,
    //   y: 300,
    //   duration: 0.7,
    //   ease: "Circ.easeOut",
    // });

    return () => {
      container.removeEventListener("mouseenter", onMouseEnterContainer1);
      container.removeEventListener("mouseleave", onMouseLeaveContainer1);
    };
  }, [ref1]);
  return (
    <section id="about" ref={ref1} className="home__section aboutsection">
      {/* <h2
        className="aboutsection__heading moveupanim1"
        style={{ color: "white" }}
      >
        About */}
      {/* </h2> */}

      {/* <div className="servicessection__toptext moveupanim1"> */}
      {/* <strong>Black Elephant</strong> 'black swan' and the 'elephant in the
        room' gives name to the inertia that prevents brands from driving
        significant and impactful digital marketing.
        <br />
        <br /> */}
      {/* <strong>
          Black Swan + Elephant in the room = Black Elephant Digital
        </strong> */}
      {/* <br /> */}
      {/* </div> */}

      <div className="servicessection__toptext moveupanim1">
        <div className="row servicessection__toptext__row">
          <div className="col col-3 servicessection__toptext__row__righttext">
            BE Digital
          </div>
          <div className="col col-1">
            <div className="servicessection__toptext__line"></div>
          </div>

          <div className="col col-7 col-md-7 servicessection__toptext__row__lefttext">
            black swan{" "}
            <span className="servicessection__toptext__row__lefttext__plus">
              +
            </span>{" "}
            elephant in the room
          </div>
        </div>
      </div>
      <br />
      <br />

      <div className="servicessection__middletext moveupanim1">
        Black Elephant Digital was conceived in keeping with the digital
        marketing needs of the new world, where marketing is for digital
        natives.
        <br />
        <br />
        Going digital is no longer a choice, but an inevitability. Black
        Elephant specializes in easing this transition for brands, and covering
        all bases to create cohesive brands across all digital channels.
        <br />
        <br />
        Quality content, simple yet striking design, and purposeful, goal-driven
        amplification across all mediums. <br />
        <br />
        Strategic marketing- that is what Black Elephant Digital does best.
      </div>

      <div className="aboutsection__buttons moveupanim1">
        {/* <a
          // href="/approach"
          className="btn btn-dark aboutsection__buttons__btn1"
        >
          advertising legacy
        </a> */}

        <Button
          className="btn-dark"
          text="advertising legacy"
          url="http://cdcsgroup.com/"
          targetBlank
          isATag
          fillColor="#e387c2"
        />
        <Button
          className="btn-dark"
          text="meet the team"
          url="/team/"
          isATag
          fillColor="#a79af8"
        />
        {/* <a className="btn btn-dark aboutsection__buttons__btn2">
          meet the team
        </a> */}
      </div>
    </section>
  );
}
