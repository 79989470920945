import logo1 from "../../../images/logo/01 c.png";
import logo2 from "../../../images/logo/02 c.png";
import logo3 from "../../../images/logo/03 c.png";
import logo4 from "../../../images/logo/04 c.png";
import logo5 from "../../../images/logo/05 c.png";
import logo6 from "../../../images/logo/06 c.png";
import logo7 from "../../../images/logo/07 c.png";
import logo8 from "../../../images/logo/08.png";
import logo9 from "../../../images/logo/09 c.png";
import logo10 from "../../../images/logo/10 c.png";
import logo11 from "../../../images/logo/11 c.png";
import logo12 from "../../../images/logo/26.png";
import logo13 from "../../../images/logo/13 c.png";
import logo14 from "../../../images/logo/14 c.png";
import logo15 from "../../../images/logo/15 c.png";
import logo16 from "../../../images/logo/16 c.png";
import logo17 from "../../../images/logo/17 c.png";
import logo18 from "../../../images/logo/18 c.png";
import logo19 from "../../../images/logo/19 c.png";
import logo20 from "../../../images/logo/20 c.png";
import logo21 from "../../../images/logo/21 c.png";
import logo22 from "../../../images/logo/22 c.png";
import logo23 from "../../../images/logo/23 c.png";
import logo24 from "../../../images/logo/25 c.png";
import logo25 from "../../../images/logo/eclipsemattresslogo.png";
import logo26 from "../../../images/logo/her-moneylogo.png";
import logo27 from "../../../images/logo/bal-bharti-school.png";
import logo28 from "../../../images/logo/ornaarc_logo.png";
import logo29 from "../../../images/logo/dps_gn_logo.png";

import logo31 from "../../../images/logo/cbv_logo.png";
import logo32 from "../../../images/logo/episodes_logo.png";
import logo33 from "../../../images/logo/manifest_logo.png";
import logo34 from "../../../images/logo/Chinmaya_mission.png";

import _ from "lodash";

const partners = [
  { src: logo1, link: "https://winpeforum.com/home/", title: "WinPE Forum" },
  {
    src: logo2,
    link: "https://jayceepower.com/home/",
    title: "Jaycee",
    style: { transform: "scale(1.4)" },
  },
  {
    src: logo3,
    link: "https://chinmayavvdelhi.ac.in/",
    title: "Chinmaya Vidyalaya",
  },
  {
    src: "https://blackelephantdigital.com/static/chinmaya-vidyalaya-chinmayamission-7b4ef6326be9d82a3b8395ea79316b22.png",
    link: "https://chinmayamission.com",
    title: "Chinmaya Vidyalaya - Chinmaya Mission",
    style: { transform: "scale(0.6) translateY(-80px)", height: "auto" },
  },
  // {
  //   src: logo28,
  //   link: "https://chinmayamission.com",
  //   title: "CCMT",
  //   style: { transform: "scale(0.50) translateY(-80px)", height: "auto" },
  // },
  // {
  //   src: logo30,
  //   link: "",
  //   title: "Chinmaya Prakashan",
  //   style: { transform: "scale(0.90) ", height: "auto" },
  // },
  {
    src: "https://blackelephantdigital.com/static/chinmaya-prakashan-1d6cc66a14f123473caac662292a56aa.jpeg",
    link: "",
    title: "Chinmaya Prakashan",
    style: { transform: "scale(0.90) ", height: "auto" },
  },
  {
    src: "https://blackelephantdigital.com/static/chinmaya-upahar-ac1daf2d728bd12a73eec6bfcfac77df.png",
    link: "",
    title: "Chinmaya Upahar",
    style: { transform: "scale(0.90) ", height: "auto" },
  },
  {
    src: logo4,
    link: "https://bawaindustries.com/",
    title: "Bawa Industries",
    style: { transform: "scale(1.2)" },
  },
  {
    src: logo5,
    link: "https://bawamasalacompany.com/",
    title: "Bawa Masala Company",
  },
  { src: logo6, link: "http://archdesignsstudio.com/", title: "A&D Studio" },
  { src: logo7, link: "https://ailumbooks.com/", title: "Ailum Books" },
  {
    src: logo8,
    link: "https://weconnectinternational.org/",
    title: "WEConnect International",
    style: { transform: "scale(1.4)" },
  },
  {
    src: logo9,
    link: "https://titikshapublicschool.com/",
    title: "Titksha Public School",
    style: { transform: "scale(1.4)" },
  },
  {
    src: logo10,
    link: "https://weconnectthinkbig.com/",
    title: "Think Big",
    style: { transform: "scale(1.4)" },
  },
  {
    src: logo11,
    link: "http://ramsons.co.in/",
    title: "Ramsons Group",
    style: { transform: "scale(1.3)" },
  },
  {
    src: logo12,
    link: "https://www.indiansma.in/",
    title: "ISMA",
    style: { transform: "scale(1.4)" },
  },
  {
    src: logo13,
    link: "http://edugateways.com/hef/",
    style: { transform: "scale(1.7)" },
    title: "Hariharan Education Foundation",
  },
  {
    src: logo14,
    link: "http://edugateways.com/",
    title: "Edugate Education and Media",
    style: { transform: "scale(1.4)" },
  },
  {
    src: logo15,
    link: "https://www.dpsgreaterranchi.in/",
    title: "Delhi Public School, Greater Ranchi",
    style: { transform: "scale(1.4)" },
  },
  {
    src: logo16,
    link: "http://www.dpsbhagalpur.com/",
    title: "Delhi Public School, Bhagalpur",
    style: { transform: "scale(1.4)" },
  },
  {
    src: logo17,
    link: "https://chanakyacentre.com/index.html",
    title: "Chankya Center for Strategic Studies",
  },
  // {
  //   src: logo18,
  //   link: "http://balabibhuti.com/",
  //   title: "Balagopal & Bibhuthi",
  //   style: { transform: "scale(2)" },
  // },
  { src: logo19, link: "https://alpexonline.com/", title: "Alpex Power" },
  {
    src: logo20,
    link: "http://cdcsgroup.com/",
    style: { transform: "scale(1.9)" },
    title: "Copy Desk Creative Services",
  },
  { src: logo21, link: "http://dpsfamily.org/", title: "Delhi Public School" },
  {
    src: logo22,
    link: "https://krmangalam.com/",
    title: "K. R. Mangalam World School",
  },
  {
    src: logo23,
    link: "https://school-live.com/",
    title: "School Live",

    style: { transform: "scale(1.3)" },
  },
  {
    src: logo24,
    link: "https://diversitydialogs.com/",
    title: "Diversity Dialogs",
    style: { transform: "scale(1.6)" },
  },
  {
    src: logo25,
    link: "https://eclipsemattress.in/",
    title: "Eclipse Mattress",
    style: { transform: "scale(1.6)" },
  },
  {
    src: logo26,
    link: "https://her-mony.com",
    title: "Her-mony",
    style: { transform: "scale(1.4)" },
  },
  {
    src: logo27,
    link: "",
    title: "Baal Bharti public School",
    style: { transform: "scale(1)" },
  },
  // {
  //   src: logo28,
  //   link: "https://fornaropizza.com/",
  //   title: "Fornaro",
  //   style: { transform: "scale(1)" },
  // },
  // {
  //   src: logo29,
  //   link: "https://dpsgrnoida.in/dpsgrnoida/default.asp",
  //   title: "Delhi Public School, Greater Noida",
  //   style: { transform: "scale(1)" },
  // },
  // {
  //   src: logo30,
  //   link: "",
  //   title: "Innersoul",
  //   style: { transform: "scale(1)" },
  // },
  // {
  //   src: logo31,
  //   link: "https://cbva.in/",
  //   title: "Cbv",
  //   style: { transform: "scale(1)" },
  // },
  {
    src: logo32,
    link: "http://episodesindia.com/",
    title: "Episodes",
    style: { transform: "scale(1)" },
  },
  {
    src: "https://blackelephantdigital.com/assets/img/mmd-01.png",
    link: "https://manifestmydreamz.com/",
    title: "Manifest",
    style: { transform: "scale(1.5) translateY(25px)", height: "auto" },
  },
  {
    src: "https://blackelephantdigital.com/assets/img/mmd-02.png",
    link: "https://manifestmydreamz.com/pages/m-fest-2022",
    title: "Manifest",
    style: { transform: "scale(1.5) translateY(25px)", height: "auto" },
  },
  {
    src: logo34,
    link: "https://www.chinmayamission.com/",
    title: "Chinmaya Mission",
    style: { transform: "scale(1)" },
  },
  {
    src: "https://blackelephantdigital.com/assets/img/Partners/dtsa.png",
    link: "https://dtsa.in/",
    title: "DTS & Associates LLP",
    style: { transform: "scale(1.6) translateY(45px)", height: "auto" },
  },
];

const sortedPartners = _.sortBy(partners, [
  function (o) {
    return o.title;
  },
]);

export default sortedPartners;
