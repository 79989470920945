import React, { useEffect, useState } from "react";
import "./style.scss";
import scrollImage from "../../../images/down-arrow.png";
import ele from "../../../images/elephant 1.png";
import eleColor from "../../../images/elephant 1 color.png";
import elephantDesign from "../../../images/ele-tree.png";
import arrowDown from "../../../images/arrow-down.png";
import RequestPortfolio from "../../RequestPortfolio";
import axios from "axios";

import bottomDesignMobile from "../../../images/bottomdesigns/home-mobile.png";

import Button from "../../ButtonComponent";

import { gsap } from "gsap";

function scrollToAbout() {
  document.querySelector("#about").scrollIntoView({ behavior: "smooth" });
}

export default function HeroSection() {
  //
  const [isFormActive, setisFormActive] = useState(false);

  useEffect(() => {
    gsap.from(".textanim", {
      // scale: 5,
      x: -200,
      delay: 0.4,
      duration: 1,
      skewX: 20,
      stagger: 0.3,
      opacity: 0,
      ease: "sine.out",
    });
  }, []);

  function toggleForm() {
    setisFormActive(!isFormActive);
  }

  return (
    <section className="home__section herosection">
      {/* <div className="home__section__row"></div> */}
      <RequestPortfolio isFormActive={isFormActive} closePopup={toggleForm} />
      <div className="herosection__text">
        <div className="herosection__text__1">
          <div className="textanim">Digital</div>
          <div className="textanim">First</div>
        </div>
        <div className="herosection__text__2">
          <div className="textanim">Creative</div>
          <div className="textanim">Agency</div>
        </div>
        <br />

        <div className="herosection__text__3">
          <span className="textanim">Radically Transparent </span>
          <br />
          <span className="textanim">360° Media Management</span>
        </div>
        <br />
        {/* <div className="herosection__text__4">
          <strong className="textanim">
            Branding + Digital Journeys + Print + Studio Services
          </strong>
        </div> */}

        <span className="textanim">
          <Button
            url="https://cal.com/black-elephant-digital/15min"
            text="schedule a meeting"
            className="btn-light"
            fillColor="#3DB2FF"
            targetBlank
            isATag
          />
          <Button
            onClick={toggleForm}
            text="book a studio slot"
            className="btn-light"
            fillColor="#FAD049"
            url="/studio-booking/"
            isLink
          />
          <br />
          <Button
            onClick={toggleForm}
            text="request portfolio"
            className="btn-light"
            fillColor="#7AB4B7"
          />
        </span>
      </div>

      <div className="herosection__ele">
        <img className="herosection__ele__img" src={ele}></img>
      </div>
      <div className="herosection__ele herosection__ele--color">
        <img className="herosection__ele__img" src={eleColor}></img>
      </div>

      <div onClick={scrollToAbout} className="herosection__movedown">
        <img className="herosection__movedown__img" src={arrowDown}></img>
      </div>

      <img
        className="herosection__bottomtrees desktop"
        src={elephantDesign}
      ></img>
      <img
        className="herosection__bottomtrees mobile"
        src={bottomDesignMobile}
      ></img>
      <div className="herosection__linebottom"></div>
    </section>
  );
}
