import React, { useState } from "react";
import "./style.scss";
import { Link } from "gatsby";
import Button from "../ButtonComponent";
// import adlauch from "../../images/adlaunch.jpg";
// import jayceeLaunch from "../../images/testimonial&projects/Jaycee_post-35.jpg";
// import diversityLaunch from "../../images/testimonial&projects/DD_Web_live_SM_post-05.jpg";
// import winpeLaunch from "../../images/testimonial&projects/Web_launch Cretive winpe-01.jpg";
// import biLaunch from "../../images/testimonial&projects/BE_Web_launch Cretive BI & BMC-01.jpg";
// import alpexLaunch from "../../images/testimonial&projects/Web_launch Cretive Alpex & CV-01.jpg";

// const bs1 =
//   "https://eclipsemattress.in/static/Bestseller%20creatives-01-2db3c0c4da1d4915fad604f04bbb1f4c.jpg";
// const bs2 =
//   "https://eclipsemattress.in/static/Bestseller%20creatives-02-622abbae35343ffec7f152e0778ffd64.jpg";
// const bs3 =
// "https://eclipsemattress.in/static/Bestseller%20creatives-03-db85516807528cdbd63abcff4cfe587c.jpg";

const data = [
  {
    content: `"Black Elephant Digital is different from others as the group has worked with educational institutions and is able to provide innovative solutions for the project undertaken to make it more meaningful!"`,
    author: "Aditi Misra <br/>Director Principal",
    source: "DPS Gurugram Sec-45",
    link: "",
    src: "https://blackelephantdigital.com/assets/testimonial/dps.jpg",
    overlaycolor: "#265C3D",
  },

  {
    content: `"Thanks to you and your team for being there to guide us on our brand establishment journey...your work has been genuinely appreciated by one and all who came across the same."`,
    author:
      "Dharminder Bhatnagar <br/>Assistant Vice President, Sales Head-Retail, Government and Exports",
    source: "Jaycee Punching Solutions Pvt. Ltd.",
    link: "https://jayceesolutions.com",
    src: "https://blackelephantdigital.com/assets/testimonial/jayceesolutions.jpg",
    overlaycolor: "#265C3D",
  },

  {
    content: `"I had the opportunity of engaging with Black - Elephant for one of the very large supplier - diversity events in 2019 in my erstwhile company. The company diligently handled all the branding and marketing part of the event. And yes...the quality of works was amazing. Totally recommend this young and vibrant team of Black Elephant."`,
    author:
      "Krithika Ram <br/>Director-Sales, ThinkWright, <br/>Former Program Director - India ",
    source: "WeConnect International",
    link: "https://jayceepower.com",
    src: "https://blackelephantdigital.com/assets/testimonial/weconnect.png",
    overlaycolor: "#E83731",
    portfolio: "/portfolio/weconnect/",
  },

  {
    content: `"Big shoutout to the most amazing digital team - Black Elephant Digital led by the
    super inspiring Nitya Balagopal. All entrepreneurs out there, if you want to take your business to the next level, please reach out to BE !! They are absolutely fabulous to work with, will understand your needs perfectly well and always over deliver"`,
    author: "Sannaayaah Baldota <br/>Founder & CEO",
    source: "Manifest My Dreams",
    link: "https://manifestmydreamz.com/",
    src: "https://blackelephantdigital.com/assets/testimonial/mmd.jpg",
    overlaycolor: "#9C3AAF",
  },

  {
    content: `"The quality of content delivered by Black Elephant is excellent, and it's appreciated not only by our Jaycee Team but also by our partners and our customers."`,
    author: "Keshav Aggarwal <br/>Director ",
    source: "Jaycee Punching Solutions",
    link: "https://jayceepower.com",
    src: "https://blackelephantdigital.com/assets/testimonial/jaycee.jpg",
    overlaycolor: "#E83731",
    portfolio: "/portfolio/jaycee/",
  },
  {
    content: `"Black Elephant Digital has added value to our marketing and brand development by building and managing our websites. They have also been managing our social media marketing for some time now and this has brought us many good customers and has strengthened our presence in the market in both the industries - Textiles and renewable energy."`,
    author: "Monika Sehgal <br/>Director",
    source: "Alpex Solar",
    link: "http://www.alpexsolar.com/",
    src: "https://blackelephantdigital.com/assets/testimonial/alpex.jpg",
    overlaycolor: "#EF6537",
  },

  {
    content: `"Special shoutout to Nitya Balagopal and her team at Black Elephant Digital- being on our side since inception and giving a face and visual identity to all our ideas and thoughts. You have redefined partnership and raised the bar incredibly high."`,
    author: "Nupur Garg <br/>Founder",
    source: "WinPE Development Forum",
    link: "https://winpeforum.com",
    src: "https://blackelephantdigital.com/assets/testimonial/winpe.jpg",
    overlaycolor: "#002060",
    portfolio: "/portfolio/winpe/",
  },
  {
    content: `"Black Elephant Digital came up with innovative design options, steering away from commonly used templates - including those available on WordPress and creating multiple original graphics to make the site modern and appealing. They are very responsive and added value at every step of the redesign process. We would gladly recommend Black Elephant Digital for end-to-end services and timely delivery"`,
    author: "Sangeeta Murthi Sahgal <br/>Co-Founder",
    source: "Diversity Dialogs",
    link: "https://diversitydialogs.com",
    src: "https://blackelephantdigital.com/assets/testimonial/dd.jpg",
    overlaycolor: "#EF6C37",
  },

  {
    content: `"Appreciate the good work!"`,
    author: "Tejbir Bawa <br/>Director",
    source: "Bawa Industries",
    link: "https://bawaindustries.com",
    src: "https://blackelephantdigital.com/assets/testimonial/bi.jpg",
    overlaycolor: "#ED3833",
    portfolio: "/portfolio/bi/",
  },

  {
    content: `"Kudos to you and your team, Nitya. A thorough professional job done with a new dimension altogether."`,
    author: "Tanveer Chadha <br/>Founder & Principal Architect",
    source: "A&D Studio",
    link: "https://archdesignsstudio.com",
    src: "https://blackelephantdigital.com/assets/testimonial/adlaunch.jpg",
    overlaycolor: "#8D1D1A",
  },
];

export default function BestSellers2() {
  const [currentIndex, setCurrentIndex] = useState(0);

  function nextImage() {
    const ci = currentIndex;

    let nextIndex = ci + 1;

    if (nextIndex === data.length) {
      nextIndex = nextIndex - data.length;
    }

    const color = data[nextIndex].overlaycolor;

    const overlay = document.querySelector(".bestsellers2__rightbox__overlay");
    const overlayleft = document.querySelectorAll(
      ".bestsellers2__leftbox__overlay"
    );

    // overlay.setAttribute("style", `background-color:${color}`);
    overlayleft.forEach((item) => {
      item.setAttribute("style", `background-color:${color}`);
    });

    overlay.classList.add("bestsellers2__rightbox__overlay--animate-right");

    overlayleft.forEach((item) => {
      item.classList.add("bestsellers2__leftbox__overlay--animate-right");
    });

    setTimeout(() => {
      setCurrentIndex(nextIndex);
    }, 1500);

    setTimeout(() => {
      overlay.classList.remove(
        "bestsellers2__rightbox__overlay--animate-right"
      );

      overlayleft.forEach((item) => {
        item.classList.remove("bestsellers2__leftbox__overlay--animate-right");
      });
    }, 3000);
  }

  function prevImage() {
    const ci = currentIndex;

    let prevIndex = ci - 1;

    if (prevIndex === -1) {
      prevIndex = data.length - 1;
    }

    const overlay = document.querySelector(".bestsellers2__rightbox__overlay");
    const overlayleft = document.querySelectorAll(
      ".bestsellers2__leftbox__overlay"
    );

    const color = data[prevIndex].overlaycolor;

    overlayleft.forEach((item) => {
      item.setAttribute("style", `background-color:${color}`);
    });

    overlayleft.forEach((item) => {
      item.classList.add("bestsellers2__leftbox__overlay--animate-left");
    });
    overlay.classList.add("bestsellers2__rightbox__overlay--animate-left");

    setTimeout(() => {
      setCurrentIndex(prevIndex);
    }, 1500);

    setTimeout(() => {
      overlayleft.forEach((item) => {
        item.classList.remove("bestsellers2__leftbox__overlay--animate-left");
      });
      overlay.classList.remove("bestsellers2__rightbox__overlay--animate-left");
      // overlay.classList.remove("bestsellers2__rightbox__overlay--animate-left");
    }, 3000);
  }
  return (
    <section className="bestsellers2">
      {/* <h2 className="heading-primary">Testimonials</h2> */}

      <h2 className="bestsellers2__heading mobile">
        Testimonials & Featured Projects
      </h2>
      <div className="bestsellers2__background"></div>
      <div className="bestsellers2__container">
        {/* <div className="bestsellers2__background"></div> */}

        <div className="bestsellers2__slider row">
          <div className="col col-12 col-md-6">
            <div className="bestsellers2__rightbox">
              <div className="bestsellers2__rightbox__overlay"></div>

              <div className="bestsellers2__rightbox__img">
                <a target="_blank" href={data[currentIndex].link}>
                  <img
                    alt="bestsellers image"
                    src={data[currentIndex].src}
                  ></img>
                </a>
              </div>
              <div className="bestsellers2__rightbox__overlay"></div>
            </div>
          </div>
          <div className="col col-12 col-md-6 desktop">
            <h2 className="bestsellers2__heading">
              Testimonials &<br /> Featured Projects
            </h2>
            <div className="bestsellers2__leftbox">
              <div className="bestsellers2__leftbox__overlay"></div>

              <div>
                <div
                  className="bestsellers2__leftbox__quote"
                  dangerouslySetInnerHTML={{
                    __html: data[currentIndex].content,
                  }}
                ></div>
                <div
                  className="bestsellers2__leftbox__person"
                  dangerouslySetInnerHTML={{
                    __html: data[currentIndex].author,
                  }}
                ></div>
                <a target="_blank" href={data[currentIndex].link}>
                  <div
                    className="bestsellers2__leftbox__company"
                    dangerouslySetInnerHTML={{
                      __html: data[currentIndex].source,
                    }}
                  ></div>
                </a>

                {data[currentIndex].portfolio && (
                  <>
                    <br />
                    <Button
                      text="Explore"
                      className="btn-light"
                      // isLink
                      isATag
                      fillColor="#000"
                      url={data[currentIndex].portfolio}
                      targetBlank
                    />
                  </>
                )}
              </div>
              <div className="bestsellers2__buttons">
                <button
                  onClick={prevImage}
                  className="bestsellers2__buttons__prev"
                >
                  <i class="fa fa-arrow-left" aria-hidden="true"></i>
                </button>
                <button
                  onClick={nextImage}
                  className="bestsellers2__buttons__next"
                >
                  <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>

          <div className="col col-12 col-md-6 mobile">
            <div className="bestsellers2__leftbox">
              <div className="bestsellers2__leftbox__overlay"></div>

              <div>
                <div
                  className="bestsellers2__leftbox__quote"
                  dangerouslySetInnerHTML={{
                    __html: data[currentIndex].content,
                  }}
                ></div>
                <div
                  className="bestsellers2__leftbox__person"
                  dangerouslySetInnerHTML={{
                    __html: data[currentIndex].author,
                  }}
                ></div>

                <a href={data[currentIndex].link} target="_blank">
                  <div
                    className="bestsellers2__leftbox__company"
                    dangerouslySetInnerHTML={{
                      __html: data[currentIndex].source,
                    }}
                  ></div>
                </a>

                <button className></button>
              </div>
              <div className="bestsellers2__buttons">
                <button
                  onClick={prevImage}
                  className="bestsellers2__buttons__prev"
                >
                  <i class="fa fa-arrow-left" aria-hidden="true"></i>
                </button>
                <button
                  onClick={nextImage}
                  className="bestsellers2__buttons__next"
                >
                  <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
