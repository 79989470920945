import * as React from "react";
import Layout from "../Layout";

import "./style.scss";
import { gsap } from "gsap";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect } from "react";
// import HeroCard from "./HeroCard";
import AboutSection from "./LegacySection";
import HeroSection from "./HeroSection2";

import lp from "../../images/link preview-01.jpg";
//
// import Testimonial from "../testimonialslider";
import Testimonial from "../Testimonial";

import PartnersSection from "./PartnersSection2";
import SEO from "../seo";
import NewsletterComponent from "../NewsletterComponent";
import PressSection from "./PressSection";
const IndexPage = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger, CSSRulePlugin);

    gsap.from(".home__heading", {
      opacity: 0,
      duration: 1,
      y: -100,
      delay: 0.5,
      ease: "elastic(1,0.5)",
    });

    gsap.from(".home__logo", {
      scale: 0.5,
      ease: true,
    });

    return () => {
      ScrollTrigger.getAll().forEach((instance) => {
        instance.kill();
      });
      // This in case a scroll animation is active while the route is updated
      gsap.killTweensOf(window);
    };
  }, []);

  return (
    <Layout isHomePage={true}>
      <SEO
        title="Black Elephant Digital | Digital is Inevitable"
        description="A full service digital marketing agency by Nitya Balagopal, offering bespoke strategic marketing solutions. Headquartered in Gurgaon, India."
        img="https://blackelephantdigital.com/assets/linkpreviews/main.jpg"
      />
      <div className="home">
        <HeroSection />
        <AboutSection />
        {/* <Testimonial /> */}
        <Testimonial />
        <PartnersSection />
        {/* <PressSection /> */}
        <NewsletterComponent />
      </div>
    </Layout>
  );
};

export default IndexPage;
