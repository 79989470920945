import React, { useState } from "react";
import "./style.scss";
import axios from "axios";
import Button from "../ButtonComponent";
import spinner from "../../images/Spinner-1s-200px.gif";
import { className } from "postcss-selector-parser";

const initialState = { firstName: "", lastName: "", email: "" };
export default function NewsletterComponent() {
  const [values, setValues] = useState(initialState);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  function handleInputChange(e) {
    setValues({ ...values, [e.target.name]: e.target.value });
  }

  async function handleOnSubmit(e) {
    e.preventDefault();
    setLoading(true);
    // console.log(values);

    try {
      const response = await axios.post(
        "https://blackelephantdigital.com/api/public/index.php/newsletter",
        values
      );
      setLoading(false);
      setValues(initialState);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }
  return (
    <div className="newsletter">
      <div className="row">
        <div className="col col-12 col-md-6">
          <div className="newsletter__left">
            <div>
              <h2 className="newsletter__heading">Stay in touch</h2>
              <div className="newsletter__text">
                Receive industry news, case studies and our updates straight in
                your inbox.
              </div>
            </div>
          </div>
        </div>
        <div className="col col-12 col-md-6">
          <form onSubmit={handleOnSubmit} className="newsletter__form">
            <div className="row">
              <div className="col col-12 col-md-6">
                <div>
                  <label>First Name</label>
                  <input
                    name="firstName"
                    className="newsletter__input"
                    value={values.firstName}
                    onChange={handleInputChange}
                    required
                  ></input>
                </div>
              </div>
              <div className="col col-12 col-md-6">
                <label>Last Name</label>

                <input
                  name="lastName"
                  className="newsletter__input"
                  value={values.lastName}
                  onChange={handleInputChange}
                  required
                ></input>
              </div>

              <div className="col col-12 ">
                <label>Email</label>
                <input
                  name="email"
                  type="email"
                  className="newsletter__input"
                  value={values.email}
                  onChange={handleInputChange}
                  required
                ></input>
              </div>

              <Button
                fillColor="#76edb2"
                className="btn-light"
                disabled={loading}
                text="sign up now"
              />
              {loading && (
                <img
                  style={{
                    display: "inline-block",
                    width: "40px",
                    height: "40px",
                    padding: 0,
                    marginTop: "25px",
                  }}
                  src={spinner}
                ></img>
              )}

              {success && (
                <div>
                  Thank you! you have been successfully added to our mailing
                  list.
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
