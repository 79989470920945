import React, { useEffect, useState } from "react";
import "./style.scss";
import partners from "./partners.data";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Button from "../../ButtonComponent";

export default function PartnersSection() {
  let ref1 = React.createRef(null);

  const [showMore, setShowMore] = useState(false);
  gsap.registerPlugin(ScrollTrigger);

  // useEffect(() => {
  //   // gsap.from(".moveupanim2", {
  //   //   scrollTrigger: {
  //   //     trigger: ref1.current,
  //   //     start: "0% center",
  //   //     end: "80% center",
  //   //     toggleActions: "play none play reverse",
  //   //     // markers: true,
  //   //     // scrub: true,
  //   //   },
  //   //   duration: 0.6,
  //   //   rotate: 10,
  //   //   opacity: 0,
  //   //   stagger: 0.3,
  //   //   // ease: "sine.in",
  //   //   y: 300,
  //   // });
  //   // const cursor = document.querySelector(".layout__cursor");
  // }, [ref1]);

  let partnersData = [];
  if (!showMore) {
    partnersData = partners.slice(0, 16);
  } else {
    partnersData = partners;
  }

  function toggleShow() {
    setShowMore(!showMore);
  }

  function scrolltoele(ele) {
    const element = document.querySelector(ele);

    var headerOffset = 45;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition - headerOffset;
    const body = document.querySelector("body");
    console.log("offsetpost:", offsetPosition);
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
  return (
    <section className="home__section partnersection" ref={ref1} id="partners">
      <h2 className="partnersection__heading">Partners</h2>
      <div className="partnersection__middletext moveupanim2">
        <strong>Black Elephant Digital</strong> has got success down to a
        science, executed artfully. <br />
        Brand development across diverse industries - for diverse business
        objectives. All equally cherished – each a long term association.
      </div>

      {/* <div className="partnersection__dragtext moveupanim2">Hold and Drag</div> */}

      <div id="partner-logos" className="partnersection__container moveupanim2">
        {partnersData.map((logo, i) => (
          <div key={"partner" + i + 1} className="partnersection__logo">
            <a target="__blank" href={logo.link}>
              <img
                draggable={false}
                style={logo.style}
                className="partnersection__logo__img"
                alt="Partner"
                src={logo.src}
              />
            </a>

            {/* <div className="partnersection__logo__title">{logo.title}</div> */}
          </div>
        ))}
      </div>

      <center>
        <Button
          url="https://calendly.com/blackelephant/30min?month=2021-09"
          text={!showMore ? "explore more" : "show less"}
          className="btn-light"
          fillColor="#00aeef"
          onClick={toggleShow}
        />
      </center>
    </section>
  );
}
