import logo1 from "../../../images/logo/01 c.png";
import logo2 from "../../../images/logo/02 c.png";
import logo3 from "../../../images/logo/03 c.png";
import logo4 from "../../../images/logo/04 c.png";
import logo5 from "../../../images/logo/05 c.png";
import logo6 from "../../../images/logo/06 c.png";
import logo7 from "../../../images/logo/07 c.png";
import logo8 from "../../../images/logo/08.png";
import logo9 from "../../../images/logo/09 c.png";
import logo10 from "../../../images/logo/10 c.png";
import logo11 from "../../../images/logo/11 c.png";
import logo12 from "../../../images/logo/26.png";
import logo13 from "../../../images/logo/13 c.png";
import logo14 from "../../../images/logo/14 c.png";
import logo15 from "../../../images/logo/15 c.png";
import logo16 from "../../../images/logo/16 c.png";
import logo17 from "../../../images/logo/17 c.png";
import logo18 from "../../../images/logo/18 c.png";
import logo19 from "../../../images/logo/19 c.png";
import logo20 from "../../../images/logo/20 c.png";
import logo21 from "../../../images/logo/21 c.png";
import logo22 from "../../../images/logo/22 c.png";
import logo23 from "../../../images/logo/23 c.png";
import logo24 from "../../../images/logo/25 c.png";
import logo25 from "../../../images/logo/eclipsemattresslogo.png";
import logo26 from "../../../images/logo/her-moneylogo.png";
import logo27 from "../../../images/logo/bal-bharti-school.png";

import _ from "lodash";

const partners = [
  {
    src:
      "https://www.siliconindia.com/images/simag_images/images/startupcity-logo.jpg",
    link: "Startup City",
    title: "Startup City",
  },
];

const sortedPartners = _.sortBy(partners, [
  function (o) {
    return o.title;
  },
]);

export default sortedPartners;
